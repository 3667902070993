
  import { Vue, Options } from "vue-class-component";
  import { Prop } from "vue-property-decorator";

  import { AuthRoutesEnum } from "@/modules/auth/router";
  import { authStore } from "@/modules/auth/store";
  import { MainRoutesEnum } from "@/router/MainRoutesEnum";

  @Options({})
  export default class extends Vue {
    @Prop() topbarMenuActive: boolean;
    @Prop() profileMode: string;
    @Prop() horizontal: boolean;
    @Prop() activeTopbarItem: string;

    onMenuButtonClick(event) {
      this.$emit("menubutton-click", event);
    }

    onTopbarMenuButtonClick(event) {
      this.$emit("topbar-menubutton-click", event);
    }

    goDashboard() {
      this.$router.push({ name: MainRoutesEnum.HOME });
    }

    logOut() {
      console.debug("logout");
      this.$localStorage.clearToken();
    }

    get userName() {
      return authStore?.getters.userName;
    }

    get userRole() {
      return (
        authStore?.getters.userRole &&
        this.$t(`role.${authStore?.getters.userRole}`)
      );
    }

    get loginRoute() {
      return { name: AuthRoutesEnum.SIGN_IN };
    }

    get profileRoute() {
      return { name: AuthRoutesEnum.CURRENT_USER };
    }

    get topbarItemsClass(): any {
      return [
        "topbar-items fadeInDown",
        {
          "topbar-items-visible": this.topbarMenuActive,
        },
      ];
    }

    setLocale(locale: string) {
      this.$i18n.locale = locale;
    }
  }
