import { authStore } from "@/modules/auth/store";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class ProfilePage extends Vue {
  isLoading: boolean = true; 

  newPassword     : string = null; 
  confirmPassword : string = null;

  get currentUser(){
    return authStore.getters.me;
  }

  async loadCurrentUser() {
    try {
      this.isLoading = true;

      await authStore.actions.getMe();
    } catch (error) {
      // TODO: i18n
      this.$errorMessage(this.$t(`Loading Failed`));
    } finally {
      this.isLoading = false;
    }
  }

  onSave(): any {
    this.$successMessage(this.$t(`profile.messages.update_success`));
  }

  mounted() {
    if (!this.currentUser) {
      this.loadCurrentUser();
    }else{
      this.isLoading = false;
    }
  }
}