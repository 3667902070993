<template>
	<div class="layout-footer">
		<span class="footer-text-left">
			<img alt="Logo" src="/assets/layout/images/logo.png" />
		</span>
		<span class="footer-text-right">
			<!-- <button class="p-link"><i class="pi pi-facebook"></i></button>
			<button class="p-link"><i class="pi pi-twitter"></i></button>
			<button class="p-link"><i class="pi pi-github"></i></button> -->
		</span>
	</div>
</template>